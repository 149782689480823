@font-face {
    font-family: "TTFirsNeue";
    src: url(./fonts/TTFirsNeue-Regular.ttf);
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "TTFirsNeue";
    src: url(./fonts/TTFirsNeue-Medium.ttf);
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "TTFirsNeue";
    src: url(./fonts/TTFirsNeue-Bold.ttf);
    font-style: normal;
    font-weight: 600;
}
  

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

html,
body,
#__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    scrollbar-width: thin !important;
    scrollbar-color: #444 #666 !important;
}

.white {
    color: white !important
}

.bold {
    font-weight: 600 !important;
}

.boldy {
    font-weight: 600;
}

.word-wrap {
    word-wrap: anywhere;
}

.unstyle-anchors  a  {
    color: inherit;
    text-decoration: none;
}    


.display-block {
    display: block;
}

.noUnderline {
    text-decoration: none;
}

.drag-button {
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    border-style: none;
    cursor: pointer;
    outline: 0;
  
    min-width: 18px;
    min-height: 18px;
  }
  